@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Brandon Text Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../public/fonts/BrandonText/brandon-text-regular.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'Brandon Text Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../public/fonts/BrandonText/brandon-text-bold.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'Brandon Text Medium';
    font-style: normal;
    font-weight: bolder;
    src: url('../public/fonts/BrandonText/brandon-text-medium.ttf')
      format('truetype');
  }

  body {
    @apply antialiased;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .dropdown:hover > .dropdown-content {
    display: block;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  }

  .navCarousel {
    position: static !important;
  }

  .navCarousel .react-multiple-carousel__arrow--right,
  .navCarousel .react-multiple-carousel__arrow--left {
    background: transparent;
    position: absolute;
    top: 30px;
    border-radius: 0;
    z-index: 2;
  }

  .navCarousel .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .navCarousel .react-multiple-carousel__arrow--left {
    left: auto;
    right: 5%;
  }

  .navCarousel .react-multiple-carousel__arrow--right:hover,
  .navCarousel .react-multiple-carousel__arrow--left:hover {
    background: transparent;
    opacity: 60%;
  }

  .navCarousel .react-multiple-carousel__arrow--right::before,
  .navCarousel .react-multiple-carousel__arrow--left::before {
    color: #2d2d2d;
  }

  .hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    margin: 15px 0px;
  }
  .hr-sect:before,
  .hr-sect:after {
    content: '';
    flex-grow: 1;
    height: 2px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 15px;
  }

  .reset-nextjs-inline-styles > span {
    position: initial !important;
  }
}
